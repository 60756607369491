import axios from "@/api/axios";
import { api } from "@/api/list";
import { ElLoading } from "element-plus";
import Cookies from "js-cookie";

//向后台页面跳转的公共方法
export async function toAdmin( adminUrl?:string) {
    const instance = axios();
    instance.defaults.headers['Refresh-Token'] =  Cookies.get('refreshToken');
    const { data, code } = await api["formatToken"]();
    if (code == 0) {
    instance.defaults.headers['Refresh-Token']=null
    ElLoading.service({
        lock: true,
        text: "正在跳往后台，请稍后...",
        background: "#fff",
    });
    location.href=`${process.env.VUE_APP_PCHREF}#/${adminUrl}?adminToken=${data}`;
    
    } 
}